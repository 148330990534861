<template>
  <div>
    <b-row>
      <b-col md="3" offset-md="3">
        <b-form-datepicker  v-model="start"></b-form-datepicker>
      </b-col>
      <b-col md="3">
        <b-form-datepicker  v-model="end"></b-form-datepicker>
      </b-col>
      <b-col md="1">
        <b-button variant="success" size="sm" class="btn-icon mr-1" @click="init()"><i class="icon-md fas fa-search"></i></b-button>
      </b-col>
    </b-row>
    <!--begin::Dashboard-->
    <div class="row mt-6">
      <div class="col-xxl-6">
        <div class="card card-custom bg-gray-100 card-stretch gutter-b">
          <div class="card-header border-0 bg-danger py-5">
            <h3 class="card-title font-weight-bolder text-white">
              {{$store.back.getText('bonjour')}} {{$store.back.getUser().prenom}}, <br> {{$store.back.getText('etat_vos_leads')}}
            </h3>
          </div>
          <div class="card-body p-0 position-relative overflow-hidden">
            <apexchart class="card-rounded-bottom bg-danger" :options="chartOptions" :series="series" type="area"></apexchart>
            <div class="card-spacer mt-n40 dash-card-menu">
              <div class="row m-0">
                <router-link v-for="(l,i) in lead" :key="i" to="/lead/liste" v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="col-sm-5 rounded-xl  px-6 py-8 mb-7  mr-7" :class="'bg-light-'+l.color" style="text-align: center;">
                    <span class="d-block my-2">
                      <i :class="l.icon+' '+'text-'+l.color" class="fas fa-3x"></i>
                    </span>
                    <a :href="href+'?status_id='+l.id" class="font-weight-bold font-size-h6" :class="'text-'+l.color">
                      {{l.titre.fr}} ({{l.count}}) <br>
                      <span style=" visibility: hidden; ">0</span>
                    </a>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-6">
        <div class="card card-custom bg-gray-100 card-stretch gutter-b">
          <div class="card-header border-0 bg-danger py-5">
            <h3 class="card-title font-weight-bolder text-white">
              <br>
              {{$store.back.getText('etat_vos_devis')}}
            </h3>
          </div>
          <div class="card-body p-0 position-relative overflow-hidden">
            <apexchart class="card-rounded-bottom bg-danger" :options="chartOptions" :series="series" type="area"></apexchart>
            <div class="card-spacer mt-n40 dash-card-menu">
              <div class="row m-0">
                <router-link v-for="(d,i) in devis" :key="i" to="/devis/liste" v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="col-sm-5 rounded-xl  px-6 py-8 mb-7  mr-7" :class="'bg-light-'+d.color" style="text-align: center;">
                    <span class="d-block my-2">
                      <i :class="d.icon+' '+'text-'+d.color" class="fas fa-3x"></i>
                    </span>
                    <a :href="href+'?status_id='+d.id" class="font-weight-bold font-size-h6" :class="'text-'+d.color">
                      {{d.titre.fr}} ({{d.count}}) <br>
                      {{d.total_ht}}
                    </a>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
export default {
  name: "dashboard",
  components: {
  },
  data(){
    return {
      start:null,
      end:null,
      devis:[],
      lead:[],
      strokeColor: "#D13647",
      chartOptions: {},
      series: []
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  beforeMount() {
    //do something before mounting vue instance
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    var date = new Date();
    this.start = `${date.getFullYear()}-${date.getMonth()+1}-01`;
    this.end = `${date.getFullYear()}-${date.getMonth()+1}-${new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()}`;
    this.init();
  },
  mounted() {
    this.chartOptions = {
      chart: {
        type: "area",
        height: 200,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: this.strokeColor,
          opacity: 0.5
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 0
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.strokeColor]
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3
          }
        }
      },
      yaxis: {
        show: false,
        min: 0,
        max: 80,
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function(val) {
            return "$" + val + " thousands";
          }
        },
        marker: {
          show: false
        }
      },
      colors: ["transparent"],
      markers: {
        colors: [this.layoutConfig("colors.theme.light.danger")],
        strokeColor: [this.strokeColor],
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
  },
  methods: {
    init(){
      this.$store.back.ajax(`/analytics/dashboard?start=${this.start}&end=${this.end}`, null, (res) => {
        if(res.status === true){
          this.devis = res.data.devis;
          this.lead = res.data.lead;
        }
      })
    }
  }
};
</script>
<style media="screen">
  .bg-danger{
    background-color: #f8551a !important;
  }
</style>
